
import React, { Suspense, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Work from "views/work/index";
import Careers from "views/career/index";
import About from "views/about/AboutUs";
import Contact from "views/contact/Contact";
import { lazy } from "react";

// import DynamicPageRenderer from "views/DynamicPageRenderer";
// import ServiceDetailsPage from "views/ServiceDetailsPage";
import Home from "Components/Home/Home";
import Navbar from "Components/Navbar";
import Footer from "Components/Footer/FooterDiv";
import Banner from "Components/Home/Banner";
// import WHYCHOOSEUS from "Components/whyChooseus/whyChooseus";
import WHYCHOOSEUS from "Components/WHYCHOOSEUS/WHYCHOOSEUS";
import HappyClient from "Components/HappyClient/HappyClient";
import BlogDetail from "views/BlogDetail";
import DraftEditor from "Components/Admin/Blog/Editor/DraftEditor";
import AdminNavbar from "Components/Admin/AdminNavbar/Navbar";
import AdminJobPostForm from "Components/Admin/Job/AdminJobPostForm";
import Login from "Components/Admin/Login/Login";
import BlogPost from "Components/Admin/Blog/Editor/BlogPost";
import JobPost from "Components/Admin/Job/JobPost";
import Blogs from "views/Blogs/Blogs";
import AdminLogin from "Components/Admin/Login/AdminLogin";
import { Toaster } from "react-hot-toast";


const DynamicPageRenderer = lazy(() => import("views/DynamicPageRenderer"))
const ServiceDetailsPage = lazy(() => import("views/ServiceDetailsPage"))
// function ProtectedRoutes({ children }) {
//   const token = localStorage.getItem('token')

//   if (!token) return false
//   const decoded = JSON.parse(atob(token.split('.')[1])); // Decode token to get role
//   return decoded.role === 'admin';
// }
const ProtectedRoutes = ({ children }) => {
  const token = localStorage.getItem('token'); // Check for token in localStorage

  if (!token) {
    // If no token, redirect to admin login
    return <Navigate to="/admin/adminlogin" replace />;
  }
  return children; // If token exists, render the children components
};


function PublicLayout() {
  return (
    <div>
      {/* <ScrolltoTop/> */}
      <Navbar />
      <Routes>
        <Route path="/" element={
          <>
            <div id="/">
              <Home />
            </div>
            <div id="about">
              <About />
            </div>
            <Banner />
            <div id="why">
              <WHYCHOOSEUS />
            </div>
            <HappyClient />
            <div id="contact">
              <Contact />
            </div>
          </>
        } />
        <Route path="/services/:section/:pageTitle"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <DynamicPageRenderer />
            </Suspense>
          } />
        <Route
          path="/services/:section/:pageTitle/:cardTitle"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <ServiceDetailsPage />
            </Suspense>
          }
        />
        <Route path="/work" element={<Work />} />
        <Route path="/insights" element={<Blogs />} />
        {/* <Route path="/insights/:slug" element={<BlogDetail />}/> */}
        {/* <Route path="/insights/:blogtitle" element={<BlogDetail />}/> */}
        <Route path="/insights/:fileName" element={<BlogDetail />} />
        <Route path="/careers" element={<Careers />} />
        {/* <Route path="/about" element={<About />} /> */}
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </div>
  )
}

function AdminLayout() {

  const [isSuccessFullLogin, setIsSuccessFullLogin] = useState(true);
  return (
    <div>
      <AdminNavbar isSuccessFullLogin={isSuccessFullLogin} setIsSuccessFullLogin={setIsSuccessFullLogin} />
      <Routes>
        {/* <Route
          path="/admin/dashboard"
          element={
            <ProtectedRoutes>
              <AdminDashboard />
            </ProtectedRoutes>
          }
        /> */}

        <Route
          path="/"
          element={
            <ProtectedRoutes>
              <AdminLogin />
            </ProtectedRoutes>
          }
        />

        <Route
          path="/blog-posting"
          element={
            <ProtectedRoutes>
              <DraftEditor />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/job-posting"
          element={
            <ProtectedRoutes>
              <AdminJobPostForm />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/job-edit"
          element={
            <ProtectedRoutes>
              <JobPost />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/blog-edit"
          element={
            <ProtectedRoutes>
              <BlogPost />
            </ProtectedRoutes>
          }
        />
        <Route path="/adminlogin" element={<AdminLogin />} />
        {/* <Route path="/login" element={<Login setIsSuccessFullLogin={setIsSuccessFullLogin} />} /> */}
      </Routes>

      {/* <Routes>
       
        <Route path="/blog-posting" element={<DraftEditor />} />
        <Route path="/job-posting" element={<AdminJobPostForm />} />
        <Route path="/job-edit" element={<JobPost />} />
        <Route path="/blog-edit" element={<BlogPost />} />
        <Route path="/adminlogin" element={<AdminLogin />} />
        <Route path="/login" element={<Login setIsSuccessFullLogin={setIsSuccessFullLogin} />} />
      </Routes> */}

    </div>
  );
}

function App() {
  return (
    <div>
      <Routes>
        {/* Public Routes */}
        <Route path="/*" element={<PublicLayout />} />

        {/* Admin Routes */}
        <Route path="/admin/*" element={<AdminLayout />} />
      </Routes>
      <Toaster />
    </div>
  );
}

export default App;
