import React from 'react';
import bgimg from "../../images/Generative AI Strategy & Solution content.png"
const CaseStudiesComponent = () => {
  const caseStudies = [
    {
      title: "How Astemo is streamlining SDV innovation with cloud technology",
      description: "Discover how ProvaanTech is transforming Hitachi Astemo’s SDV development with cloud-based solutions, driving innovation, efficiency, and cost savings.",
      image: bgimg,
      link: "#"
    },
    {
      title: "Fleet Management R&D Lab and Data Platform",
      description: "Explore a fleet management leader’s digital transformation story and learn how they use technology to maximize asset use and driver safety.",
      image:  bgimg,
      link: "#"
    },
    {
      title: "How Reqtest Grew 20% with R&D Partner ProvaanTech",
      description: "Explore how Reqtest, an application lifecycle management SaaS company, transformed its R&D department and achieved remarkable growth through its partnership with ProvaanTech.",
      image:  bgimg,
      link: "#"
    },
    {
      title: "Adobe Experience Manager Case Study: Telecom Self-Service Portal Implementation",
      description: "When a leading telecommunications carrier needed to modernize its self-service client portal, ProvaanTech made it happen with Adobe Experience Manager.",
      image:  bgimg,
      link: "#"
    },
    {
      title: "Adobe Analytics & AEM Case Study: 865% Growth in Omni-channel Shopping Sales",
      description: "See how Adobe Experience Manager and Analytics implementations changed the game for one of the largest department stores in the U.S., driving revenue growth and customer satisfaction.",
      image:  bgimg,
      link: "#"
    },
    {
      title: "Insurance Product Engineering & Services",
      description: "A case study on how Insurity transformed its offerings.",
      image: bgimg,
      link: "#"
    }
  ];

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + '...';
    }
    return text;
  };

  return (
    <div className="w-full p-8 mx-auto max-w-7xl ">
      <h2 className="mb-6 text-3xl font-bold">Case Studies</h2>
      <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
        {/* {caseStudies.map((caseStudy, index) => (
          <div key={index} className="items-center overflow-hidden text-center bg-white rounded-lg shadow-xl">
            <div className="relative">
              <img src={caseStudy.image} alt={caseStudy.title} className="object-cover w-full h-48" />
            </div>
            <div className="p-6">
              <h3 className="mb-2 text-lg font-semibold">{caseStudy.title}</h3>
              <p className="mb-4 text-gray-500">{caseStudy.description}</p>
              <button className='p-1 px-2 bg-orange-400 rounded-md hover:scale-110'><a href={caseStudy.link} className="text-sm font-normal text-white">Learn More</a></button>
            </div>
          </div>
        ))} */}
        {caseStudies?.map(({ title, description, image }) => (
          <div className="items-center overflow-hidden bg-white rounded-lg shadow-xl relative pb-6">
            <div className="relative">
              <img src={image} alt={title} className="object-cover w-full h-48" />
            </div>
            <div className="p-4">
              <h3 className="mb-2 text-lg font-semibold">{truncateText(title, 20)}</h3>
              <p className="mb-4 text-gray-600">{truncateText(description, 100)}</p>
              <div className=" w-full absolute bottom-0 left-0 mb-4 ml-4">
                <button className='p-1 px-2 bg-blue-500 hover:scale-110'>
                  <span className="text-sm font-normal text-white">Learn More</span>
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CaseStudiesComponent;
