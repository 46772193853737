import React from 'react';
import sampleImage from '../../assets/pics3.jpg';
// Sample images (replace with actual image paths)
// const sampleImage = 'http://provaantech.com/wp-content/uploads/2024/02/representation-user-experience-interface-design-computer.jpg';


const cards = [
  {
    id: 1,
    image: sampleImage,
    title: 'Innovation',
    description: 'We’re rooted in Silicon Valley’s culture of innovation, continuously pushing the boundaries of technology to create impactful solutions. Our collaborative environment fosters creativity and encourages experimentation, making innovation a part of our DNA. Join us to work on cutting-edge projects and be a part of the future of technology.',
  },
  {
    id: 2,
    image: sampleImage,
    title: 'Reward & Recognition',
    description: 'Our reward and recognition program has been designed to celebrate your achievements and milestones. We believe in acknowledging the hard work and dedication of our employees through various awards, bonuses, and public recognition. Your success is our success, and we strive to create a culture where your efforts are appreciated and rewarded.',
  },
  {
    id: 3,
    image: sampleImage,
    title: 'Employee Value Proposition & Culture',
    description: 'We’re looking for bold, smart humans to join us on our journey. Our culture is built on diversity, inclusivity, and mutual respect. We value each employee’s unique perspective and encourage an open, collaborative work environment where everyone’s voice is heard. Be a part of a community that supports your growth both personally and professionally.',
  },
  {
    id: 4,
    image: sampleImage,
    title: 'Professional Development',
    description: 'We believe development is a diverse journey, providing numerous opportunities for learning and growth. From mentorship programs to continuous education, we offer resources to help you advance your career. Whether you’re just starting out or looking to take the next step, we support your professional development at every stage.',
  },
  {
    id: 5,
    image: sampleImage,
    title: 'Benefits',
    description: 'We continuously strive to maintain rewards standards that are competitive and comprehensive. Our benefits package includes health insurance, retirement plans, paid time off, and wellness programs. We aim to support your overall well-being, ensuring that you and your family have the resources you need to thrive.',
  },
  {
    id: 6,
    image: sampleImage,
    title: 'Growth',
    description: 'Our constant endeavor is to bring in the best value and opportunities for our employees. We focus on career growth and provide a clear path for advancement within the company. Through regular feedback, performance reviews, and career development plans, we help you achieve your professional goals and grow with us.',
  },
];

const JoinGlobalLogic = () => {
  return (
    <div className="container mx-auto p-6">
      <h2 className="text-3xl font-bold mb-4 text-center">Why Join Provaantech?</h2>
      <p className="mb-8 text-center">We promise you a friendly and inclusive work environment where you will learn, grow, and be challenged every day.</p>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {cards.map(card => (
          <div key={card.id} className="shadow-xl rounded-lg overflow-hidden">
            <img src={card.image} alt={card.title} className="w-full h-48 object-cover"/>
            <div className="p-4">
              <h3 className="text-xl font-semibold mb-2">{card.title}</h3>
              <p className=" text-gray-500">{card.description}</p>
             
         
              </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default JoinGlobalLogic;
