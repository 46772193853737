import React from 'react';

function EditBlogModal({ currentBlog, setCurrentBlog, showEditModal, setShowEditModal, saving, handleSaveChanges }) {
  if (!showEditModal) return null;

  return (
    <div className="fixed w-full h-full inset-0 flex items-center justify-center bg-white ">
      <div className="bg-white w-full max-w-lg p-6 rounded-lg shadow-lg">
        <h3 className="text-lg font-semibold mb-4">Edit Blog Post</h3>
        
        <label className="block mb-2 text-gray-600">Content</label>
        <textarea
          className="w-full p-2 border border-gray-300 rounded mb-4"
          rows={5}
          value={currentBlog?.content}
          onChange={(e) => setCurrentBlog({ ...currentBlog, content: e.target.value })}
        ></textarea>
        <div className="flex justify-end">
          <button
            className="px-4 py-2 bg-gray-500 text-white rounded mr-2"
            onClick={() => setShowEditModal(false)}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded"
            onClick={handleSaveChanges}
            disabled={saving}
          >
            {saving ? 'Saving...' : 'Save Changes'}
          </button>
        </div>
      </div>
    </div>
  );
}

export default EditBlogModal;
