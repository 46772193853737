import React, { useEffect, useState } from 'react';
import styles from './JobPostList.module.css';
import { BASE_APIURL } from 'utils/helpers';

const JobPostList = () => {
    const [jobPosts, setJobPosts] = useState([]); // Initialize as an empty array
    const [selectedJob, setSelectedJob] = useState(null);
    const [applicationData, setApplicationData] = useState({
        name: '',
        email: '',
        coverLetter: '',
        resume: null,
    });
    const [errorMessage, setErrorMessage] = useState('');

    // Fetch job posts from the API
    const fetchJobPosts = async () => {
        try {
            const response = await fetch(`${BASE_APIURL}/jobs`); // Your API endpoint
            if (!response.ok) {
                throw new Error('Failed to fetch job postings');
            }
            const data = await response.json();
            setJobPosts(data.jobPosts || []); // Ensure data is an array
        } catch (error) {
            console.error('Error fetching job posts:', error);
            // setErrorMessage('Failed to fetch job postings. Please try again later.');
        }
    };

    // Handle application form input changes
    const handleChange = (e) => {
        const { name, value, type, files } = e.target;
        setApplicationData({
            ...applicationData,
            [name]: type === 'file' ? files[0] : value,
        });
    };

    // Handle apply button click
    const handleApplyClick = (job) => {
        setSelectedJob(job);
    };

    // Handle application form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('name', applicationData.name);
        formData.append('email', applicationData.email);
        formData.append('coverLetter', applicationData.coverLetter);
        formData.append('resume', applicationData.resume);

        try {
            const response = await fetch(`/api/jobs/${selectedJob.title}/apply`, {
                method: 'POST',
                body: formData,
            });
            if (!response.ok) {
                throw new Error('Failed to submit application');
            }
            alert(`Application submitted for ${selectedJob.title}!`);
            setApplicationData({ name: '', email: '', coverLetter: '', resume: null });
            setSelectedJob(null);
        } catch (error) {
            console.error('Error submitting application:', error);
            alert('Failed to submit application. Please try again later.');
        }
    };

    useEffect(() => {
        fetchJobPosts(); // Fetch job posts when the component mounts
    }, []);

    return (
        <div className={styles.jobPostList}>
            <h2 className={styles.title}>Job Postings</h2>
            {errorMessage && <p className={styles.error}>{errorMessage}</p>}
            {jobPosts.length === 0 ? (
                <p className={styles.noVacancy}>No Vacancy Available</p>
            ) : (
                jobPosts.map((job, index) => (
                    <div key={index} className={styles.jobPost}>
                        <h3 className={styles.jobTitle}>{job.title}</h3>
                        <p className={styles.jobDescription}>{job.description}</p>
                        <p className={styles.jobRequirements}><strong>Requirements:</strong> {job.requirements}</p>
                        <p className={styles.jobLocation}><strong>Location:</strong> {job.location}</p>
                        <p className={styles.jobSalary}><strong>Salary Range:</strong> {job.salaryRange}</p>
                        <button className={styles.applyButton} onClick={() => handleApplyClick(job)}>Apply</button>

                        {selectedJob === job && (
                            <div className={styles.applicationForm}>
                                <h3 className={styles.applicationTitle}>Apply for {job.title}</h3>
                                <form onSubmit={handleSubmit}>
                                    <input 
                                        type="text" 
                                        name="name" 
                                        placeholder="Your Name" 
                                        value={applicationData.name} 
                                        onChange={handleChange} 
                                        required 
                                    />
                                    <input 
                                        type="email" 
                                        name="email" 
                                        placeholder="Your Email" 
                                        value={applicationData.email} 
                                        onChange={handleChange} 
                                        required 
                                    />
                                    <textarea 
                                        name="coverLetter" 
                                        placeholder="Cover Letter" 
                                        value={applicationData.coverLetter} 
                                        onChange={handleChange} 
                                        required 
                                    />
                                    <input 
                                        type="file" 
                                        name="resume" 
                                        accept=".pdf,.doc,.docx" 
                                        onChange={handleChange} 
                                        required 
                                    />
                                    <button type="submit" className={styles.submitButton}>Submit Application</button>
                                    <button className={styles.cancelButton} onClick={() => handleApplyClick(null)}>Cancel</button>
                                </form>
                            </div>
                        )}
                    </div>
                ))
            )}
        </div>
    );
};

export default JobPostList;
