import React from 'react';
import Navbar from 'Components/Navbar'; // Adjust the path according to your project structure
import Footer from 'Components/Footer'; // Adjust the path according to your project structure
import { FaUsers, FaHandsHelping, FaComments, FaChalkboardTeacher } from 'react-icons/fa';
import Jobcontainer from './Jobcontainer';
import Card1 from './card1'
import Card2 from './card2'
import Card3 from './card3'
import JobPostList from './JobPostList';
import bgimg from "../../images/Digital Quality Assurancebanner.png"
const CareerSection = () => {
  return (
    <div>
      {/* <Navbar/> */}
    
      <div className="relative w-full">
        <img 
          src={bgimg}
          alt="Background"
          className=" bg-cover w-full  h-96"
        />
        <div className="absolute top-60 left-40 transform -translate-x-1/2 -translate-y-1/2 flex justify-center">
          <h1 className="text-white text-6xl font-bold">CAREER</h1>
        </div>
      </div>



<Card2/>
<Card3/>
     {/* <Jobcontainer/> */}
     {/* <Card1/> */}
<JobPostList/>
      {/* <Footer/> */}
    </div>
  );
}

export default CareerSection;
